import registerServiceWorker from "./src/utils/registerServiceWorker";
registerServiceWorker();

if ("serviceWorker" in navigator && "PushManager" in window) {
  navigator.serviceWorker.ready
    .then((registration) => {
      registration.pushManager
        .subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlB64ToUint8Array(
            process.env.GATSBY_VAPID_PUBLIC_KEY
          ),
        })
        .then((subscription) => {
          return fetch("/.netlify/functions/subscribe", {
            method: "POST",
            body: JSON.stringify(subscription),
            headers: {
              "Content-Type": "application/json",
            },
          });
        })
        .catch((error) => {
          console.error("Failed to subscribe the user:", error);
        });
    })
    .catch((error) => {
      console.error("Service Worker not ready:", error);
    });
}

function urlB64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export const onClientEntry = () => {
  if (!document.getElementById("Cookiebot")) {
    // Create Cookiebot script element
    const cookiebotScript = document.createElement("script");
    cookiebotScript.id = "Cookiebot";
    cookiebotScript.src = "https://consent.cookiebot.com/uc.js";
    cookiebotScript.setAttribute(
      "data-cbid",
      "57f891a2-ea8c-4c68-9d3d-840d1b227d18"
    );
    cookiebotScript.setAttribute("data-blockingmode", "auto");
    cookiebotScript.type = "text/javascript";

    // Append the Cookiebot script to the head of the document
    document.head.appendChild(cookiebotScript);
  }

  if (!document.getElementById("CookieDeclaration")) {
    // Create CookieDeclaration script element
    const cookieDeclarationScript = document.createElement("script");
    cookieDeclarationScript.id = "CookieDeclaration";
    cookieDeclarationScript.src =
      "https://consent.cookiebot.com/57f891a2-ea8c-4c68-9d3d-840d1b227d18/cd.js";
    cookieDeclarationScript.type = "text/javascript";
    cookieDeclarationScript.async = true;

    // Append the CookieDeclaration script to the head of the document
    document.head.appendChild(cookieDeclarationScript);
  }
};
