exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apie-mus-js": () => import("./../../../src/pages/apie-mus.js" /* webpackChunkName: "component---src-pages-apie-mus-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-komentavimo-taisykles-js": () => import("./../../../src/pages/komentavimo-taisykles.js" /* webpackChunkName: "component---src-pages-komentavimo-taisykles-js" */),
  "component---src-pages-kontaktai-js": () => import("./../../../src/pages/kontaktai.js" /* webpackChunkName: "component---src-pages-kontaktai-js" */),
  "component---src-pages-lietuvos-tiktokeriai-js": () => import("./../../../src/pages/lietuvos-tiktokeriai.js" /* webpackChunkName: "component---src-pages-lietuvos-tiktokeriai-js" */),
  "component---src-pages-naujiena-pateikta-js": () => import("./../../../src/pages/naujiena-pateikta.js" /* webpackChunkName: "component---src-pages-naujiena-pateikta-js" */),
  "component---src-pages-naujienlaiskis-patvirtinimas-js": () => import("./../../../src/pages/naujienlaiskis/patvirtinimas.js" /* webpackChunkName: "component---src-pages-naujienlaiskis-patvirtinimas-js" */),
  "component---src-pages-pateikti-naujiena-js": () => import("./../../../src/pages/pateikti-naujiena.js" /* webpackChunkName: "component---src-pages-pateikti-naujiena-js" */),
  "component---src-pages-privatumo-politika-js": () => import("./../../../src/pages/privatumo-politika.js" /* webpackChunkName: "component---src-pages-privatumo-politika-js" */),
  "component---src-pages-reklama-js": () => import("./../../../src/pages/reklama.js" /* webpackChunkName: "component---src-pages-reklama-js" */),
  "component---src-pages-zinute-issiusta-js": () => import("./../../../src/pages/zinute-issiusta.js" /* webpackChunkName: "component---src-pages-zinute-issiusta-js" */),
  "component---src-templates-category-template-js": () => import("./../../../src/templates/category-template.js" /* webpackChunkName: "component---src-templates-category-template-js" */),
  "component---src-templates-post-template-js": () => import("./../../../src/templates/post-template.js" /* webpackChunkName: "component---src-templates-post-template-js" */),
  "component---src-templates-redirect-template-js": () => import("./../../../src/templates/redirect-template.js" /* webpackChunkName: "component---src-templates-redirect-template-js" */),
  "component---src-templates-search-template-js": () => import("./../../../src/templates/search-template.js" /* webpackChunkName: "component---src-templates-search-template-js" */),
  "component---src-templates-tag-template-js": () => import("./../../../src/templates/tag-template.js" /* webpackChunkName: "component---src-templates-tag-template-js" */)
}

